import PersistentDrawer from "../components_focus/PersistentDrawer"
import * as React from 'react';
import TopHeader from "../components_focus/TopHeader";
import { useEffect } from "react";
import axios from "axios";
import { Typography, Button } from "@mui/joy";
import { useParams } from 'react-router-dom';

import { useNavigate } from "react-router-dom";
import { MainUserContext } from "../App";

import { Helmet } from 'react-helmet';
import NoPaper from "../components_focus/NoPaper";
import { pdfjs } from 'react-pdf';
import PDFViewer from "../components_focus/PDFViewer";
import { Stack } from "@mui/joy";
import FloatingChatBot from "../components_focus/FloatingChatBot";
import SurveyFab from "../common_components/SurveyFab";
import RightSideMenu from "../common_components/RightSideMenu";


export const UserContext = React.createContext(null);


export default function Focus() {

    const { loggedIn } = React.useContext(MainUserContext);
    
    const [paper, setPaper] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(true);
    const [enableChatInput, setEnableChatInput] = React.useState(false);
    const [currentChatId, setCurrentChatId] = React.useState(null);
    const { userId } = React.useContext(MainUserContext);

    const [messageIsLoading, setMessageIsLoading] = React.useState(false);
    const [dots, setDots] = React.useState('');
    const [messageIsLoadingDetails, setMessageIsLoadingDetails] = React.useState("");

    const [messages, setMessages] = React.useState([]);
    const [chats, setChats] = React.useState([]);

    const [openSources, setOpenSources] = React.useState(false);
    const [sources, setSources] = React.useState([]);

    const [showSurvey, setShowSurvey] = React.useState(false);
    
    const { setUser } = React.useContext(MainUserContext);
    const { setUserId } = React.useContext(MainUserContext);
    const { setLoggedIn } = React.useContext(MainUserContext);
    const { setEmail } = React.useContext(MainUserContext);

    const { chat_id } = useParams(); // Access the optional `chat_id` parameter

    React.useEffect(() => {
        console.log('Checking if user is logged in');
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/protected', {withCredentials: true}).then(response => {
            console.log('User logged response:', response.data);
            if (response.data.user) {
                axios.get(process.env.REACT_APP_API_URL + '/api/users/mail/' + response.data.user.mail).then((response) => {
                    setUser(response.data[0]);
                    setUserId(response.data[0].user_id);
                    setLoggedIn(true);
                    setEmail(response.data[0].mail);
                });
            } else {
                setLoggedIn(false);
                window.open('https://ederspark.com/?page=platform', '_self');
            };
        }
        ).catch(error => {
            console.error(error);
            setLoggedIn(false);
            window.open('https://ederspark.com/?page=platform', '_self');
        });
      }, []);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/polls/' + userId)
        .then(response => {
            setShowSurvey(!response.data.user_has_answered);
        })
        .catch(error => {
            console.error('Error fetching survey data:', error);
        }
    );
    }, [userId]);


    React.useEffect(() => {
        if (chat_id) {
          // If conv_id is present, do something with it (e.g., fetch conversation data)
          axios.get(process.env.REACT_APP_API_URL + '/api/focus/check_conversation/' + chat_id + '&' + userId)
            .then(response => {
                if (response.data.length === 0) {
                    return;
                }
                setCurrentChatId(chat_id);
            })
            .catch(error => {
                console.error('Error fetching conversation data:', error);
            });
        } else {
          console.error('No chat_id provided');
        }
      }, [chat_id]);

    const drawerSize = 300;
    const drawerMargin = 15;
    const extraMargin = 15;
    const marginLeft = isOpen ? drawerSize + drawerMargin + extraMargin : 0;

    const navigate = useNavigate();    

    useEffect(() => {
        if (!currentChatId) {
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/focus/sources/' + currentChatId)
        .then(response => {
            setPaper(response.data[0]);
        })
        .catch(error => {
        });
    }, [currentChatId]);

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }
    const [currentChatName, setCurrentChatName] = React.useState('');
    return (
        <UserContext.Provider value={{ messages, setMessages, chats, setChats, messageIsLoading, setMessageIsLoading, dots, setDots, messageIsLoadingDetails, setMessageIsLoadingDetails, currentChatId, setCurrentChatId, currentChatName, setCurrentChatName, sources, setSources, openSources, setOpenSources, paper }}>
        <Helmet>
            <title>SparkAI Focus</title>
        </Helmet>
        <div>
            <PersistentDrawer isOpen={isOpen} drawerSize={drawerSize} drawerMargin={drawerMargin} setCurrentChatName={setCurrentChatName} />
            <main style={{ flexGrow: 0, flexShrink: 0, marginLeft: marginLeft, transition: 'margin-left 0.3s'}}>
                <TopHeader isOpen={isOpen} toggleDrawer={toggleDrawer} currentChatName={currentChatName}/>
            </main>
            {paper === null ? <NoPaper/> : 
                <PDFViewer url={paper.url} />
            }
        </div>
        <div
            style={{position: 'fixed', top: 100, right: 50, display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center', alignItems: 'center'}}
        >
            <RightSideMenu />
        </div>
        {currentChatId != null && <FloatingChatBot />}
        {showSurvey && <SurveyFab setShowSurvey={setShowSurvey} />}
        </UserContext.Provider>
    )
}