import '../index.css';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';


export default function ChatSearch({setFilterName}) {
    return (
        <div 
            className="flex justify-center items-center"
        >
            <input
                className="w-11/12 h-10 rounded-md px-3 border-2 border-gray-400 shadow-sm"    
                placeholder="Search a chat" onChange={(e) => setFilterName(e.target.value)}/>
            <MagnifyingGlassIcon
                className="w-5 h-5 text-gray-500 z-50 absolute right-10 cursor-text"
            />
        </div>
    )
}