import React from 'react';
import { Input, IconButton, Switch, Stack, Typography, Tooltip } from '@mui/joy';
import { Search } from '@mui/icons-material';
import { SparkQueryContext } from '../pages/SparkQuery';
import axios from 'axios';
import { Card } from '@mui/material';
import { IoIosInformationCircleOutline } from "react-icons/io";


export default function SearchInput() {
    const {showResults, setShowResults} = React.useContext(SparkQueryContext);
    const {searchQuery, setSearchQuery} = React.useContext(SparkQueryContext);
    const {results, setResults} = React.useContext(SparkQueryContext);
    const {nResults, setNResults} = React.useContext(SparkQueryContext);
    const {refinedQueries, setRefinedQueries} = React.useContext(SparkQueryContext);
    const {smartAISearch, setSmartAISearch} = React.useContext(SparkQueryContext);

    const handleSearch = () => {
        setShowResults(true);
        setResults([]);
        if (!smartAISearch) {
            setRefinedQueries([]);
            axios.get(process.env.REACT_APP_API_URL + '/api/semantic_scholar/paper/search?query=' + searchQuery)
                .then((response) => {
                    setNResults(response.data.total);
                    setResults(response.data.data);
                })
                .catch((error) => {
                    console.error('Error searching for papers:', error);
                }
            );
            setRefinedQueries([]);
            setSmartAISearch(false);
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/ia/refine_query?query=' + searchQuery)
            .then((response) => {
                setRefinedQueries(response.data.queries);
                axios.get(process.env.REACT_APP_API_URL + '/api/semantic_scholar/paper/search?query=' + response.data.queries[0])
                    .then((response) => {
                        setNResults(response.data.total);
                        setResults(response.data.data);
                    })
                    .catch((error) => {
                        console.error('Error searching for papers:', error);
                    }
            );
            })
            .catch((error) => {
                console.error('Error generating refined queries:', error);
            }
        );
    };

    const toggleSmartAISearch = () => {
        setSmartAISearch(!smartAISearch);
    };

    return (
        <form onSubmit={(e) => {
            e.preventDefault(); // Prevent the page from reloading
            handleSearch(); // Call the search handler
        }}>
            <Card sx={{ height: 80, borderRadius: '10px', boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)' }}>
            <Input
                required
                endDecorator={
                    <IconButton 
                        variant="soft" 
                        onClick={handleSearch} 
                        sx={{ width: '30px', height: '30px', borderRadius: '10px' }}
                    >
                        <Search />
                    </IconButton>
                }
                variant="plain" 
                placeholder="Enter your scientific question here" 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
                sx={{
                    width: '900px',
                    maxWidth: '80vw',
                    height: '40px', 
                    fontSize: '24px', 
                    borderRadius: '10px', 
                }}
            />
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography level="body-sm" sx={{ color: 'text.secondary' }}>Smart AI Search</Typography>
                <Switch variant="outlined" checked={smartAISearch} onChange={() => toggleSmartAISearch()} />
                <Tooltip title="Keep this toggled to enable AI natural language understanding for your search query. Disabled if you want to search for the exact query you entered (useful for searching specific scientific articles).">
                    <IconButton size="md" sx={{ color: 'text.secondary' }}><IoIosInformationCircleOutline /></IconButton>
                </Tooltip>
            </Stack>
            </Card>
        </form>        
    );
}