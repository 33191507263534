import React from "react";
import { Container, Grid, Box, Avatar, Typography, Button, Card, CardContent, Menu, MenuItem, Stack } from "@mui/joy";
import { AccountCircle, CalendarToday, Language, Email, Public, Money, Lock } from "@mui/icons-material";
import { MainUserContext } from "../App";
import axios from "axios";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/20/solid";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom';


const PersonalInfo = ({ user }) => {

    const { setUser} = React.useContext(MainUserContext);
    const [ editName, setEditName ] = React.useState(false);
    const [ userName, setUserName ] = React.useState(user.name);

    const handleChangeUserName = (e) => {
        e.preventDefault();
        setUser({ ...user, name: userName });
        setEditName(false);
        console.log('User:', user);
        axios.put(process.env.REACT_APP_API_URL + '/api/users/change_name/', { id: user.user_id, name: userName })
            .then((response) => {
                console.log('Name changed:', response.data);
            })
            .catch((error) => {
                console.error('Error changing name:', error);
        });
    }

    return (
        <div
            className="flex flex-col gap-4"
        >
            <text
                className="text-xl md:text-2xl font-semibold text-gray-600"
            >
                Personal Information
            </text>
            <text
                className="text-gray-600"
            >
                Manage your personal information, including your name, email and institution you are affiliated with (if any).
            </text>

            <div
                className="grid grid-cols-1 md:grid-cols-2 gap-4"
            >
                <div
                    className="relative flex flex-col gap-2 border-2 border-emerald-200 p-4 rounded-lg"
                >
                    <button
                        className="absolute top-2 right-2 text-gray-600 p-1 h-8 w-8"
                        onClick={() => setEditName(!editName)}
                    >
                        {!editName ? <PencilSquareIcon className="w-6 h-6 text-emerald-500" />
                        : <XCircleIcon className="w-6 h-6 text-red-500" onClick={() => setEditName(false)} />}
                    </button>

                    {/* Content */}
                    <div className="flex flex-row items-center gap-2">
                        <UserCircleIcon className="w-6 h-6 text-emerald-500" />
                        
                        <text className="text-lg md:text-xl font-semibold text-gray-600">
                            Name
                        </text>
                    </div>
                    {!editName ?
                        <text className="text-gray-600">
                            {user.name == null ? "Not specified" : user.name}
                        </text>
                        :
                        <>
                        <form
                            onSubmit={handleChangeUserName}
                        >
                            <input
                                className="w-full p-2 rounded-lg border-2 border-emerald-200"
                                type="text"
                                placeholder="Enter your name"
                                defaultValue={user.name}
                                onChange={(e) => setUserName(e.target.value)}
                                />
                            <div
                                className="relative"
                                >
                                <button
                                    className="rounded-lg p-2 absolute right-0 bottom-2"
                                    type="submit"
                                    >
                                    <CheckCircleIcon className="w-6 h-6 text-emerald-500" />
                                </button>
                            </div>
                        </form>
                        </>
                    }
                </div>

                <div
                    className="flex flex-col gap-2 border-2 border-emerald-200 p-4 rounded-lg"
                >
                    <div
                        className="flex flex-row items-center gap-2"
                    >
                        <EnvelopeIcon className="w-6 h-6 text-emerald-500" />
                        <text
                            className="text-lg md:text-xl font-semibold text-gray-600"
                        >
                            Email
                        </text>
                    </div>
                    <text
                        className="text-gray-600"
                    >
                        {user.mail}
                    </text>
                </div>
                
                <div
                    className="flex flex-col gap-2 border-2 border-emerald-200 p-4 rounded-lg"
                >
                    <div 
                        className="flex flex-row items-center gap-2"
                    >
                        <UserCircleIcon className="w-6 h-6 text-emerald-500" />
                        <text
                            className="text-lg md:text-xl font-semibold text-gray-600"
                        >
                            Institution / Company
                        </text>
                    </div>
                    <text
                        className="text-gray-600"
                    >
                        {(user.institution == null) ? 'Not affiliated' : user.institution}
                    </text>
                </div>
            </div>
        </div>
    );
}

const PlansBilling = ({ user }) => {
    return (
        <div
            className="flex flex-col gap-4"
        >
            <text
                className="text-xl md:text-2xl font-semibold text-gray-600"
            >
                Plans & Billing
            </text>
            <text
                className="text-gray-600"
            >
                Manage your subscription and billing information here.
            </text>

            <div
                className="grid grid-cols-1 md:grid-cols-2 gap-4"
            >
                <div
                    className="flex flex-col gap-2 border-2 border-emerald-200 p-4 rounded-lg"
                >
                    <div 
                        className="flex flex-row items-center gap-2"
                    >
                        <Money className="w-6 h-6 text-emerald-500" />
                        <text
                            className="text-lg md:text-xl font-semibold text-gray-600"
                        >
                            Plan
                        </text>
                    </div>
                    <text
                        className="text-gray-600"
                    >
                        Free
                    </text>
                </div>

                <div
                    className="flex flex-col gap-2 border-2 border-emerald-200 p-4 rounded-lg"
                >
                    <div
                        className="flex flex-row items-center gap-2"
                    >
                        <CalendarToday className="w-6 h-6 text-emerald-500" />
                        <text
                            className="text-lg md:text-xl font-semibold text-gray-600"
                        >
                            Expires
                        </text>
                    </div>
                    <text
                        className="text-gray-600"
                    >
                        No expiration date
                    </text>
                </div>
            </div>
        </div>
    );
}

const Security = ({ user }) => {

    const { setUser } = React.useContext(MainUserContext);
    const [ editPassword, setEditPassword ] = React.useState(false);
    const [ oldPassword, setOldPassword ] = React.useState('');
    const [ password, setPassword ] = React.useState('');
    const [ confirmPassword, setConfirmPassword ] = React.useState('');

    const [ oldPasswordMessage, setOldPasswordMessage ] = React.useState('');
    const [ passwordMessage, setPasswordMessage ] = React.useState('');

    const [ passwordFeedback, setPasswordFeedback ] = React.useState('');

    const handlePasswordMatch = (e) => {
        e.preventDefault();
        setConfirmPassword(e.target.value);
        if (password !== e.target.value) {
            setPasswordMessage('Passwords do not match');
        } else {
            setPasswordMessage('');
        }
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setPasswordMessage('Passwords do not match');
            return;
        }
        axios.put(process.env.REACT_APP_API_URL + '/api/users/change_password/', { id: user.user_id, password: password, old_password: oldPassword })
        .then((response) => {
            console.log('Password changed:', response.data);
            if (response.data.status === 'error') {
                setPasswordMessage(response.data.password_message);
                setOldPasswordMessage(response.data.old_password_message);
            } else {
                setUser({ ...user, password: password });
                setEditPassword(false);
                console.log('User:', user);
                setPasswordFeedback('Password changed successfully!');
            }
        })
        .catch((error) => {
            console.error('Error changing password:', error);
        });
    }

    // pasword change
    return (
        <div
            className="flex flex-col gap-4"
        >
            <text
                className="text-xl md:text-2xl font-semibold text-gray-600"
            >
                Security
            </text>
            <text
                className="text-gray-600"
            >
                Manage your account security settings here.
            </text>

            <div
                className="grid grid-cols-1 md:grid-cols-2 gap-4"
            >
                <div
                    className="flex flex-col gap-2 border-2 border-emerald-200 p-4 rounded-lg"
                >
                    <div 
                        className="flex flex-row items-center gap-2"
                    >
                        <Lock className="w-6 h-6 text-emerald-500" />
                        <text
                            className="text-lg md:text-xl font-semibold text-gray-600"
                        >
                            Password
                        </text>
                    </div>
                    {!editPassword ? <>
                        <text
                            className="text-gray-600"
                            >
                            Change your password
                        </text>
                        <button
                            className="bg-emerald-500 text-white rounded-lg p-2"
                            onClick={() => setEditPassword(!editPassword)}
                            >
                            Change password
                        </button>
                        <text
                            className="text-green-500 text-sm font-semibold"
                        >
                            {passwordFeedback}
                        </text>
                    </>
                    :
                    <>
                        <form
                            onSubmit={handleChangePassword}
                        >
                            <div
                                className="flex flex-col gap-2"
                            >
                                <input
                                    required
                                    className="w-full p-2 rounded-lg border-2 border-emerald-200"
                                    type="password"
                                    placeholder="Enter your old password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                <text
                                    className="text-red-500 text-sm mb-1"
                                >
                                    {oldPasswordMessage}
                                </text>
                                <input
                                    required
                                    className="w-full p-2 rounded-lg border-2 border-emerald-200"
                                    type="password"
                                    placeholder="Enter your new password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    />
                                <input
                                    required
                                    className="w-full p-2 rounded-lg border-2 border-emerald-200"
                                    type="password"
                                    placeholder="Confirm your new password"
                                    value={confirmPassword}
                                    onChange={handlePasswordMatch}
                                    />
                                <text
                                    className="text-red-500 text-sm"
                                >
                                    {passwordMessage}
                                </text>
                                <div
                                    className="flex flex-row gap-1"
                                >
                                    <button
                                        className="bg-emerald-500 text-white rounded-lg p-2"
                                        type="submit"
                                        >
                                        Confirm
                                    </button>
                                    <button
                                        className="bg-red-500 text-white rounded-lg p-2"
                                        onClick={() => setEditPassword(false)}
                                        >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                    }
                </div>

                <div
                    className="flex flex-col gap-2 border-2 border-emerald-200 p-4 rounded-lg"
                >
                    <div
                        className="flex flex-row items-center gap-2"
                    >
                        <Language className="w-6 h-6 text-emerald-500" />
                        <text
                            className="text-lg md:text-xl font-semibold text-gray-600"
                        >
                            Language
                        </text>
                    </div>
                    <text
                        className="text-gray-600"
                    >
                        Change your language settings
                    </text>
                    <button
                        disabled
                        className="bg-gray-300 text-white rounded-lg p-2 cursor-not-allowed"
                    >
                        Change language
                    </button>
                </div>
            </div>
        </div>
    );
}

const SidebarMenu = ( { personalInfoSelected, setPersonalInfoSelected, plansSelected, setPlansSelected, securitySelected, setSecuritySelected } ) => {

    return (
        <div>
            <div 
                className="flex flex-col items-center gap-2 mt-4"
            >
                <button
                    className={`w-full p-2 rounded-lg ${personalInfoSelected ? "bg-emerald-50 text-gray-600 border-2 border-emerald-200" : "text-gray-600"}`} 
                    onClick={() => {
                        setPersonalInfoSelected(true);
                        setPlansSelected(false);
                        setSecuritySelected(false);
                    }}
                >
                    Personal Information
                </button>
                <button
                    className={`w-full p-2 rounded-lg ${plansSelected ? "bg-emerald-50 text-gray-600 border-2 border-emerald-200" : "text-gray-600"}`}
                    onClick={() => {
                        setPersonalInfoSelected(false);
                        setPlansSelected(true);
                        setSecuritySelected(false);
                    }}
                >
                    Plans & Billing
                </button>
                <button
                    className={`w-full p-2 rounded-lg ${securitySelected ? "bg-emerald-50 text-gray-600 border-2 border-emerald-200" : "text-gray-600"}`}
                    onClick={() => {
                        setPersonalInfoSelected(false);
                        setPlansSelected(false);
                        setSecuritySelected(true);
                    }}
                >
                    Security
                </button>
            </div>
        </div>
    );
  };

const Account = () => {
    const { user, setUser } = React.useContext(MainUserContext);
    const { userId, setUserId } = React.useContext(MainUserContext);
    const { loggedIn, setLoggedIn } = React.useContext(MainUserContext);
    const { email, setEmail } = React.useContext(MainUserContext);

    const [ personalInfoSelected, setPersonalInfoSelected ] = React.useState(true);
    const [ plansSelected, setPlansSelected ] = React.useState(false);
    const [ securitySelected, setSecuritySelected ] = React.useState(false);

    const logOut = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/logout', {withCredentials: true}).then(response => {
            console.log('Log out response:', response.data);
            window.open('https://ederspark.com/?page=platform', '_self');
        }).catch(error => {
            console.error('Error logging out:', error);
        });
        setLoggedIn(false);
    }

    React.useEffect(() => {
        console.log('Checking if user is logged in');
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/protected', {withCredentials: true}).then(response => {
            console.log('User logged response:', response.data);
            if (response.data.user) {
                axios.get(process.env.REACT_APP_API_URL + '/api/users/mail/' + response.data.user.mail).then((response) => {
                    setUser(response.data[0]);
                    setUserId(response.data[0].user_id);
                    setLoggedIn(true);
                    setEmail(response.data[0].mail);
                });
            } else {
                setLoggedIn(false);
                window.open('https://ederspark.com/?page=platform', '_self');
            };
        }
        ).catch(error => {
            console.error(error);
            setLoggedIn(false);
            window.open('https://ederspark.com/?page=platform', '_self');
        });
      }, []);

    const navigate = useNavigate();

    return (
        <div
            className="flex flex-col items-center w-full h-full"
        >
            <div
                className="flex flex-col w-5/6 m-10 gap-10"
            >
                <Helmet>
                    <title>EderSpark Account</title>
                </Helmet>
                <div
                    className="flex justify-between items-center"
                >
                    <text
                        className="text-xl md:text-2xl font-semibold text-gray-600"
                    >
                        EderSpark Account
                    </text>
                    <div 
                        className="flex flex-row gap-2"
                    >
                        <button
                            className="bg-emerald-500 cursor-pointer rounded-lg shadow-md"
                            onClick={() => {
                                navigate('/chat');
                            }}
                        >
                            <div
                                className="flex flex-row items-center gap-1 p-1"
                            >
                                <text
                                    className="text-white font-semibold"
                                    >
                                    Go back
                                </text>
                                <ArrowUturnLeftIcon className="w-5 h-5 text-white" />
                            </div>
                        </button>
                        <button
                            className="bg-white border-2 border-red-400 cursor-pointer rounded-lg"
                            onClick={logOut}
                        >
                            <div
                                className="flex flex-row items-center gap-1 p-1"
                            >
                                <text
                                    className="text-red-500 font-semibold"
                                    >
                                    Log out
                                </text>
                                <ArrowLeftEndOnRectangleIcon className="w-6 h-6 text-red-500" />
                            </div>
                        </button>
                    </div>
                </div>
                <div
                    className="flex flex-row items-center w-full h-full gap-8"
                >
                    {/* Left Sidebar */}
                    <div
                        className="flex flex-col w-1/4 h-full"
                    >
                    <div
                            className="flex flex-col items-center gap-1"
                        >
                            <Avatar sx={{ width: 80, height: 80 }} />
                                <text
                                    className="text-md md:text-lg font-semibold text-gray-600 text-center"
                                >
                                    {(user.name == null) ? 'Not specified' : user.name}
                                </text>
                            <text
                                className="text-xs md:text-sm text-gray-400 text-center"
                            >
                                {user.mail}
                            </text>

                            <SidebarMenu
                                personalInfoSelected={personalInfoSelected}
                                plansSelected={plansSelected}
                                securitySelected={securitySelected} 
                                setPersonalInfoSelected={setPersonalInfoSelected} 
                                setPlansSelected={setPlansSelected}
                                setSecuritySelected={setSecuritySelected} />
                        </div>
                    </div>
                    {(personalInfoSelected) ? <PersonalInfo user={user} /> : null}
                    {(plansSelected) ? <PlansBilling user={user} /> : null}
                    {(securitySelected) ? <Security user={user} /> : null}
                </div>
            </div>
        </div>
    );
};

export default Account;
