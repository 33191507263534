import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import SparkAI_logo from '../assets/focus_only_logo_upscaled.png';
import '../App.css';
import ChatList from './ChatButton';
import ChatSearch from './ChatSearch';
import { LuSettings2 } from "react-icons/lu";
import { AiOutlinePlus } from "react-icons/ai";
import '../index.css';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

import { UserContext } from '../pages/Focus';
import { UserIcon } from '@heroicons/react/20/solid';

import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import { Stack } from '@mui/joy';

import { MainUserContext } from '../App';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function ProfileMenu() {

    const { setLoggedIn } = React.useContext(MainUserContext);

    const logOut = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/logout', {withCredentials: true}).then(response => {
            console.log('Log out response:', response.data);
            window.open('https://ederspark.com/?page=platform', '_self');
        }).catch(error => {
            console.error('Error logging out:', error);
        });
        setLoggedIn(false);
    }
    const navigate = useNavigate();

    return (
        <Dropdown>
        <MenuButton variant="plain" sx={{borderRadius: "10rem", padding: '0.2rem'}}>
            <UserIcon 
                className="h-8 w-8 shadow-inner rounded-full"
            />
        </MenuButton>
        <Menu placement="bottom-end" sx={{zIndex: 9999}}>
            <MenuItem onClick={() => navigate('/account')}>
            <ListItemDecorator>
                <FaUserCircle />
            </ListItemDecorator>{' '}
            My account
            </MenuItem>
            <ListDivider />
            <MenuItem variant="soft" color="danger" onClick={() => logOut()}>
            <ListItemDecorator sx={{ color: 'inherit' }}>
                <MdLogout />
            </ListItemDecorator>{' '}
            Log out
            </MenuItem>
        </Menu>
        </Dropdown>
    )
}

function DrawerHeader() {

    const { user } = React.useContext(MainUserContext);
    const [mail, setMail] = React.useState('error loading mail');

    React.useEffect(() => {

        if (user) {
            setMail(user.mail);
        }
    }, [user]);

    return (
        <div
            className="flex flex-row justify-between items-center px-2"
        >
            <div 
                className="flex flex-row justify-start items-center gap-3"
            >
                <img src={SparkAI_logo} alt="SparkAI_logo" 
                    className="h-6 w-6"
                />
                <text
                    className="text-lg font-bold"
                >
                    Chats
                </text>
            </div>
            <div
                className="hidden md:flex flex-row justify-end items-center gap-1 text-gray-500"
            >
                <text
                    className="text-xs"
                >{mail}</text>
                <ProfileMenu/>
            </div>
        </div>
    );
}


function DrawerBody({ drawerSize, setCurrentChatName }) {
    const [filterName, setFilterName] = React.useState('');
    return (
        <div 
            className={`w-[${drawerSize}] justify-normal rounded-md flex flex-col bg-purple-100 pt-4 shadow-inner h-full`}
        >
            <ChatSearch setFilterName={setFilterName}/>
            <div
                className="flex flex-col justify-between h-full"
            >
                <ChatList
                    filterName={filterName} setCurrentChatName={setCurrentChatName}/>
            </div>
        </div>
    );
}

export default function PersistentDrawer({ isOpen, drawerSize, drawerMargin, setCurrentChatName, setLoggedIn }) {

    // if is on mobile, set drawerSize to 10
    if (window.innerWidth < 768) {
        drawerSize = window.innerWidth - 20;
    }

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={isOpen}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    boxShadow: 'none', // Remove the shadow
                    border: 'none', // Remove the border if there's any
                    backgroundColor: 'transparent', // Transparent background
                    zIndex: 1001, // Set the z-index to 9999
                },
            }}
        >
            <div
                className={`flex flex-col gap-3 w-[${drawerSize}] bg-transparent h-full rounded-md m-[${drawerMargin}]`} 
            style={{ width: drawerSize + 5, display: 'flex', flexDirection: 'column', gap: '10px', backgroundColor: 'transparent', height:'100vh', borderRadius: '25px', margin: drawerMargin}}>
                <DrawerHeader drawerSize={drawerSize} setLoggedIn={setLoggedIn}/>
                <DrawerBody drawerSize={drawerSize} setCurrentChatName={setCurrentChatName}/>
            </div>
        </Drawer>
    );
}