import React, { useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import FocusOnlyLogo from '../assets/focus_only_logo_upscaled.png';
import FocusLogo from '../assets/focus_logo_upscaled.png';
import SparkQueryOnlyLogo from '../assets/sparkquery_only_logo_upscaled.png';
import SparkQueryLogo from '../assets/sparkquery_logo_upscaled.png';
import SparkAIOnlyLogo from '../assets/sparkai_new_only_logo.png';
import SparkAILogo from '../assets/sparkai_new_logo.png';

import { useNavigate } from 'react-router-dom';

const RightSideMenu = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <div
    className={`right-side-menu fixed top-1/2 right-0 transform -translate-y-1/2 hidden md:flex flex-col gap-2 p-1 bg-white/80 rounded-l-lg shadow-md overflow-hidden transition-all duration-300 ${
      expanded ? 'w-36' : 'w-16'
    }`}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <text
        className="text-xs text-gray-600 font-semibold text-center"
      >
        Tool menu
      </text>
      <Tooltip title="SparkAI" placement="left">
        <Button
          sx={{
            justifyContent: expanded ? 'flex-start' : 'center',
            padding: '8px 16px',
            width: '100%',
          }}
          color="primary"
          onClick={() => window.open('/chat', '_blank')}
          startIcon={
            <img
              src={SparkAIOnlyLogo}
              alt="SparkAI"
              style={{ width: 24, height: 24 }}
            />
          }
        >
          {expanded && <img src={SparkAILogo} alt="SparkAI" style={{ width: 75, paddingLeft: 5 }} />}
        </Button>
      </Tooltip>

      <Tooltip title="Focus" placement="left">
        <Button
          sx={{
            justifyContent: expanded ? 'flex-start' : 'center',
            padding: '8px 16px',
            width: '100%',
          }}
          color="primary"
          onClick={() => window.open('/focus', '_blank')}
          startIcon={
            <img
              src={FocusOnlyLogo} // Replace with your image URL
              alt="Focus"
              style={{ width: 24, height: 24 }}
            />
          }
        >
          {expanded && <img src={FocusLogo} alt="Focus" style={{ width: 75, paddingLeft: 5 }} />}
        </Button>
      </Tooltip>

      <Tooltip title="SparkQuery" placement="left">
        <Button
          sx={{
            justifyContent: expanded ? 'flex-start' : 'center',
            padding: '8px 16px',
            width: '100%',
          }}
          color="primary"
          onClick={() => window.open('/sparkquery', '_blank')}
          startIcon={
            <img
              src={SparkQueryOnlyLogo}
              alt="SparkQuery"
              style={{ width: 24, height: 24 }}
            />
          }
        >
          {expanded && <img src={SparkQueryLogo} alt="SparkQuery" style={{ width: 80, paddingLeft: 5 }} />}
        </Button>
      </Tooltip>
    </div>
  );
};

export default RightSideMenu;
