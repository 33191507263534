import { useNavigate } from "react-router-dom";
import sparkquery_logo from '../assets/sparkquery_logo_slogan_upscaled.png';
import { Button } from "@mui/material";
import { Stack, Typography } from "@mui/joy";


export default function LogoSparkQuery() {
    const navigate = useNavigate();
    return (
        <Stack direction="column" spacing={2} sx={{ paddingBottom: '1em' }}>
            <Button onClick={() => navigate('/sparkquery')}>
                <img src={sparkquery_logo} alt="SparkQuery" style={{ width: '400px'}} onClick={() => window.location.reload()} />
            </Button>
            <Typography level="body-lg" textAlign="center">Your natural language search engine for AI research papers</Typography>
        </Stack>
    );
}