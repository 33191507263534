import React, { useState } from 'react';
import { Box, Modal, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, Button, IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';
import { Poll, Close } from '@mui/icons-material';
import { purple } from '@mui/material/colors';
import { motion } from 'framer-motion';
import axios from 'axios';
import { MainUserContext } from '../App';

const SurveyFab = ({setShowSurvey}) => {
    const [open, setOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState('');
    const [isStudent, setIsStudent] = useState(false);
    const { userId } = React.useContext(MainUserContext);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOptionChange = (event) => setSelectedOption(event.target.value);
    const handleCheckboxChange = (event) => setIsStudent(event.target.checked);

    const handleSubmit = () => {
        // Handle the form submission, maybe save to database or send to an API
        axios.post(process.env.REACT_APP_API_URL + '/api/polls', { userId: userId, option: selectedOption, isUCOStudent: isStudent })
            .then(response => {
            }
        ).catch(error => {
            console.error('Error submitting survey:', error);
        });
        handleClose();
        setShowSurvey(false);
    };

    return (
        <>
            {/* Floating Action Button with Pulse Animation */}
            <Fab 
                color="primary" 
                aria-label="survey" 
                onClick={handleOpen}
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    bgcolor: purple[500],
                    '&:hover': { bgcolor: purple[700] },
                    boxShadow: '0px 4px 20px rgba(128, 0, 128, 0.4)', // Pulsing shadow effect
                    width: 40,
                    height: 40,
                }}
            >
                <Poll sx={{ width: 25, height: 25 }} />
            </Fab>

            {/* Survey Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="survey-modal-title"
                aria-describedby="survey-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: purple[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Typography id="survey-modal-title" variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center', color: purple[700] }}>
                        We want to know more about you!
                    </Typography>
                    
                    <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                        <FormLabel component="legend">How did you find us?</FormLabel>
                        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                            <FormControlLabel value="friend" control={<Radio />} label="Friend recommendation" />
                            <FormControlLabel value="poster" control={<Radio />} label="A poster" />
                            <FormControlLabel value="instagram" control={<Radio />} label="Instagram" />
                            <FormControlLabel value="tiktok" control={<Radio />} label="Tiktok" />
                            <FormControlLabel value="linkedin" control={<Radio />} label="LinkedIn" />
                            <FormControlLabel value="google" control={<Radio />} label="Google" />
                        </RadioGroup>
                    </FormControl>

                    <FormControlLabel
                        control={<Checkbox checked={isStudent} onChange={handleCheckboxChange} />}
                        label="Are you a student of University of Córdoba?"
                    />

                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                        * This survey is only for statistical purposes.
                    </Typography>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleSubmit}
                            sx={{
                                bgcolor: purple[500],
                                '&:hover': { bgcolor: purple[700] },
                                boxShadow: '0px 4px 12px rgba(128, 0, 128, 0.4)',
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default SurveyFab;
