import React, { useState } from 'react';
import { Input, Stack, IconButton, Button, Typography } from '@mui/joy';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import sparkquery_logo from '../assets/sparkquery_logo_slogan_upscaled.png';
import { SparkQueryContext } from '../pages/SparkQuery';
import SearchInput from './SearchInput';
import SwitchToChat from '../common_components/SwitchToChat';
import LogoSparkQuery from './LogoSparkQuery';


export default function SearchBarView() {

    return (
        <>
        <Stack direction="column" spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <LogoSparkQuery />
            <SearchInput />    
        </Stack>
        </>
    );
}