import { Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Set up the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const PDFViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null); // Store the blob URL here

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Construct the proxy URL
  const pdfProxyUrl = `${process.env.REACT_APP_API_URL}/api/focus/pdf?url=${encodeURIComponent(url)}`;

  // Fetch the PDF file with Authorization header
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(pdfProxyUrl, {
          method: 'GET',
          headers: {
            'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac',
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to load PDF: ${response.statusText}`);
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    fetchPdf();
  }, [pdfProxyUrl]);

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center',
      position: 'absolute',
      zIndex: -10,
      left: '14vw',
    }}>
      <Stack
        direction={'row'}
        sx={{ position: 'fixed', bottom: 75, left: '50%', transform: 'translateX(-50%)', zIndex: 10, bgcolor: 'white', borderRadius: 10, padding: 1, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', alignItems: 'center', justifyContent: 'center' }}
        gap={2}
      >
        <Typography>
          Total Pages: {numPages}
        </Typography>
      </Stack>

      {pdfBlobUrl ? (
        <Document
          style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          file={pdfBlobUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.error('Error loading PDF:', error)}
        >
          {/* Render each page sequentially */}
          {Array.from(new Array(numPages), (el, index) => (
            <Page 
              key={`page_${index + 1}`} 
              pageNumber={index + 1} 
              scale={1.5} // Increase the scale for larger text
              style={{ margin: '20px 0' }} 
            />
          ))}
        </Document>
      ) : (
        <Typography>Loading PDF...</Typography>
      )}
    </div>
  );
};

export default PDFViewer;
