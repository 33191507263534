
const fastModelMaxInputLength = 2048;
const smartModelMaxInputLength = 1024;

/**
 * Calculate the maximum input length for the fast model
 * @param {number} inputLength - The input length
 * 
 * @returns {boolean} - true if the input length is greater than the maximum input length for the fast model, false otherwise
 */
export function calcMaxInputLengthFastModel(inputLength) {
    console.log('Input length received:', inputLength);
    console.log('Fast model max input length:', fastModelMaxInputLength);
    return inputLength > fastModelMaxInputLength;
}

/**
 * Calculate the maximum input length for the smart model
 * @param {number} inputLength - The input length
 * 
 * @returns {boolean} - true if the input length is greater than the maximum input length for the smart model, false otherwise
 */
export function calcMaxInputLengthSmartModel(inputLength) {
    console.log('Input length received:', inputLength);
    console.log('Smart model max input length:', smartModelMaxInputLength);
    return inputLength > smartModelMaxInputLength;
}

/**
 * Calculate the maximum input length for a given model
 * @param {string} model - The model
 * @param {number} inputLength - The input length
 * 
 * @returns {boolean} - true if the input length is greater than the maximum input length for the model, false otherwise
 */
export function calcMaxInputLength(model, inputLength) {
    console.log('Model received:', model);
    console.log('Input length received:', inputLength);
    if (model === 'smart') {
        return calcMaxInputLengthSmartModel(inputLength);
    } else {
        return calcMaxInputLengthFastModel(inputLength);
    }
}