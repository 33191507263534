import React, { useState } from 'react';
import SearchBarView from '../sparkquery_components/SearchBarView';
import SearchResultsView from '../sparkquery_components/SearchResultsView';
import RightSideMenu from '../common_components/RightSideMenu';
import { MainUserContext } from '../App';
import axios from 'axios';

export const SparkQueryContext = React.createContext(null);

const SparkQuery = () => {

    const [searchQuery, setSearchQuery] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState([]);
    const [nResults, setNResults] = useState(0);
    const [refinedQueries, setRefinedQueries] = useState([]);
    const { loggedIn, user } = React.useContext(MainUserContext);
    const [page, setPage] = React.useState(1);
    const [smartAISearch, setSmartAISearch] = React.useState(true);
    const [value, setValue] = React.useState(refinedQueries[0]);
    const [isPageLoading, setIsPageLoading] = React.useState(false);

    const { setUser } = React.useContext(MainUserContext);
    const { setUserId } = React.useContext(MainUserContext);
    const { setLoggedIn } = React.useContext(MainUserContext);
    const { setEmail } = React.useContext(MainUserContext);


    const values_provider = {
        searchQuery, setSearchQuery, showResults, setShowResults, results, setResults, nResults, setNResults,
        refinedQueries, setRefinedQueries, page, setPage, smartAISearch, setSmartAISearch, value, setValue,
        isPageLoading, setIsPageLoading
    };

    React.useEffect(() => {
      console.log('Checking if user is logged in');
      axios.get(process.env.REACT_APP_API_URL + '/api/auth/protected', {withCredentials: true}).then(response => {
          console.log('User logged response:', response.data);
          if (response.data.user) {
              axios.get(process.env.REACT_APP_API_URL + '/api/users/mail/' + response.data.user.mail).then((response) => {
                  setUser(response.data[0]);
                  setUserId(response.data[0].user_id);
                  setLoggedIn(true);
                  setEmail(response.data[0].mail);
              });
          } else {
              setLoggedIn(false);
              window.open('https://ederspark.com/?page=platform', '_self');
          };
      }
      ).catch(error => {
          console.error(error);
          setLoggedIn(false);
          window.open('https://ederspark.com/?page=platform', '_self');
      });
    }, []);

    return (
        <SparkQueryContext.Provider value={values_provider}>
            { showResults ? <SearchResultsView /> : <SearchBarView /> }
            <RightSideMenu />
        </SparkQueryContext.Provider>
    );
};

export default SparkQuery;
