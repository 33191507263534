import { Badge, Tooltip } from "@mui/material";
import { BiArrowToLeft } from "react-icons/bi";
import { BiArrowToRight } from "react-icons/bi";
import '../index.css';
import SparkAI_logo from '../assets/focus_only_logo_upscaled.png';
import Typography from "@mui/joy/Typography";
import { useMediaQuery } from '@mui/material';
import { Stack } from "@mui/joy";
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid';


export default function TopHeader({isOpen, toggleDrawer, currentChatName}){

    const isSmallScreen = useMediaQuery('(max-width: 1000px)');

    return (
        <div 
            className="flex flex-row h-20 justify-start items-center gap-8 fixed w-full shadow-[inset_0_50px_50px_-10px_rgba(255,255,255,0.9)] z-[9999]"
        >
            <div
                className=" hover:bg-gray-100 rounded-full ml-1"
            >
                {isOpen ? <ChevronLeftIcon
                    className="cursor-pointer w-7 h-7"
                    onClick={toggleDrawer}
                    /> : 
                    <ChevronRightIcon
                    className="cursor-pointer w-7 h-7"
                    onClick={toggleDrawer}
                    />}
            </div>
            <div
                className="w-auto hidden md:block max-w-4xl flex-row items-center gap-2"
            >
                <Tooltip title="Chat selected" placement="bottom">
                <text
                    className="text-gray-800 text-md font-semibold whitespace-nowrap text-overflow-ellipsis overflow-x-hidden flex-grow justify-start"
                    >
                        {currentChatName}
                </text>
                </Tooltip>
            </div>
            <div
                className="w-auto"    
            >
                <Tooltip title="Model selected" placement="bottom">
                    <text
                        className="text-gray-600 text-md text-center whitespace-nowrap"
                    >
                        Focus 1b
                    </text>
                </Tooltip>
            </div>
            {!isSmallScreen &&
                <div 
                    className="flex flex-row gap-2 flex-grow justify-end items-center fixed right-12"
                >
                    <img
                        className="w-5 h-5"
                        src={SparkAI_logo} alt="Focus Logo"
                    />
                    <text
                        className="text-2xl font-semibold"
                    >
                        Focus
                    </text>
                </div>
            }
        </div>
    )
}