import React, { useState } from 'react';
import { Input, Stack, IconButton, Button, Typography, Skeleton, CircularProgress, Tooltip } from '@mui/joy';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import sparkquery_logo from '../assets/sparkquery_logo_slogan_upscaled.png';
import { SparkQueryContext } from '../pages/SparkQuery';
import SearchInput from './SearchInput';
import SwitchToChat from '../common_components/SwitchToChat';
import ResultsList from './ResultsList';
import QueryTabs from './QueryTabs';
import LogoSparkQuery from './LogoSparkQuery';
import { IoIosInformationCircleOutline } from "react-icons/io";



function ResultsRendering() {

    const {refinedQueries, setRefinedQueries} = React.useContext(SparkQueryContext);

    return (
        <Stack direction="column" spacing={2} sx={{ justifyContent: 'start', alignItems: 'center', paddingTop: '1em' }}>
            <LogoSparkQuery />
            <SearchInput />
            {   refinedQueries.length > 0 &&
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography level="h4" sx={{ color: 'text.secondary' }}>Relevant topics detected by AI</Typography>
                <Tooltip title="These are the results of your search query. Click on a any of the topics to see the results for that topic.">
                    <IconButton size="md" sx={{ color: 'text.secondary' }}><IoIosInformationCircleOutline /></IconButton>
                </Tooltip>
            </Stack>
            }
            <QueryTabs />
            <ResultsList />
        </Stack>
    )
}

function ResultSkeleton() {
    const skeletonWidth = 1400;
    const skeletonHeight = 80;
    return (
        <Skeleton variant="rectangular" width={skeletonWidth} height={skeletonHeight} sx={{ borderRadius: 10 }} />
    )
}

function SkeletonsRendering() {
    const skeletonWidth = 1400;
    const skeletonHeight = 80;
    return (
        <Stack direction="column" spacing={2} sx={{ justifyContent: 'start', alignItems: 'center', paddingTop: '1em' }}>
            <LogoSparkQuery />
            <SearchInput />
            <CircularProgress size='sm' variant='plain'/>
            <QueryTabs />
            <Stack direction="column" spacing={2} sx={{ justifyContent: 'start', alignItems: 'center', width: '100%'}}>
                {[...Array(6)].map((_, index) => (
                    <ResultSkeleton key={index} />
                ))}
            </Stack>
        </Stack>
    )
}

export default function SearchResultsView() {
    const {searchQuery, setSearchQuery} = React.useContext(SparkQueryContext);
    const {showResults, setShowResults} = React.useContext(SparkQueryContext);
    const {results, setResults} = React.useContext(SparkQueryContext);

    return (
        <>
            { results.length > 0 ? <ResultsRendering /> : <SkeletonsRendering /> }
        </>
    );
}