import React from 'react';
import { Paper, Divider, Chip, Button } from '@mui/material';
import { CircularProgress, LinearProgress, Tooltip } from '@mui/joy';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Card } from '@mui/joy';
import { IoSparklesOutline } from "react-icons/io5";
import axios from 'axios';
import { SparkQueryContext } from '../pages/SparkQuery';
import { IoIosJournal } from "react-icons/io";
import { IoCloudDownloadSharp } from "react-icons/io5";
import { HiMiniMagnifyingGlassCircle } from "react-icons/hi2";
import { FaCrown } from "react-icons/fa";
import { FaFire } from "react-icons/fa6";
import { MainUserContext } from '../App';
import { useNavigate } from 'react-router-dom';



export default function ResultView({result}) {
    const [authorsString, setAuthorsString] = React.useState('');
    const [aiInsights, setAiInsights] = React.useState('Loading AI insights...');
    const {searchQuery} = React.useContext(SparkQueryContext);
    const [isOnDatabase, setIsOnDatabase] = React.useState(false);
    const { userId } = React.useContext(MainUserContext);
    const [isLoadingRequestFocus, setIsLoadingRequestFocus] = React.useState(false);
    const [errorMessageRequestFocus, setErrorMessageRequestFocus] = React.useState(null);
    const [successMessageRequestFocus, setSuccessMessageRequestFocus] = React.useState(null);
    const {page, setPage} = React.useContext(SparkQueryContext);
    const {value, setValue} = React.useContext(SparkQueryContext);


    const navigate = useNavigate();

    React.useEffect(() => {
        // Reset messages when changing page
        setErrorMessageRequestFocus(null);
        setSuccessMessageRequestFocus(null);
        setIsLoadingRequestFocus(false);
        setAiInsights('Loading AI insights...');
    }, [page, value]);

    React.useEffect(() => {
        const authorsString = result.authors.map(author => author.name).join(', ');
        setAuthorsString(authorsString);
        axios.get(process.env.REACT_APP_API_URL + '/api/qdrant/paper_exists/' + result.paperId)
            .then((response) => {
                setIsOnDatabase(response.data.exists);
            })
            .catch((error) => {
                console.error('Error checking if paper exists:', error);
            });
    }, [result]);

    

    const getAiInsights = () => {
        if (aiInsights !== 'Loading AI insights...') {
            return;
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/arlai/premades/insights/', {
            query: searchQuery,
            title: result.title,
            abstract: result.abstract
        }).then(response => {
            setAiInsights(response.data.ai_insights);
        }).catch(error => {
            console.error(error);
            setAiInsights(null);
        });
    }

    const openInFocus = () => {
        axios.post(process.env.REACT_APP_API_URL + '/api/focus/chat/create', {
            conversation_name: result.title,
            user_id: userId,
            paper_id: result.paperId,
            title: result.title,
            authors: authorsString,
            abstract: result.abstract,
            url: result.openAccessPdf ? result.openAccessPdf.url : null
        })
        .then(response => {
            navigate('/focus/' + response.data.conversation_id);
        })
        .catch(error => {
            console.error('Error creating focus chat:', error);
        });
    }

    const requestFocus = () => {
        setIsLoadingRequestFocus(true);
        axios.post(process.env.REACT_APP_API_URL + '/api/qdrant/add_paper/', {
            url: result.openAccessPdf ? result.openAccessPdf.url : null,
            paper_id: result.paperId,
            title: result.title,
            authors: authorsString,
            abstract: result.abstract
        })
        .then(response => {
            console.log('Added paper to database:', response.data);
            if (response.data.status) {
                setIsOnDatabase(true);
                setSuccessMessageRequestFocus('Paper correctly processed! Open it in Focus clicking "Open in Focus!"');
            }
            else {
                console.error('Error adding paper to database:', response.data.error);
                setErrorMessageRequestFocus('Despite being "Open Access", the publisher does not allow to freely access the paper. We are sorry for the inconvenience :(');
            }
            setIsLoadingRequestFocus(false);
        })
        .catch(error => {
            console.error('Error adding paper to database:', error);
            setErrorMessageRequestFocus('Error adding paper to database. Please try again later.');
            setIsLoadingRequestFocus(false);
        });
    }

    return (
        <Card 
            sx={{
                padding: '1em',
                margin: '1em',
                maxWidth: '900px',
                minWidth: '80vw',
                textAlign: 'left',
                borderRadius: 10,
            }}
        >
            <Accordion sx={{ padding: 1 }} onChange={getAiInsights}>
                <AccordionSummary>
                    <Stack direction="column" spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <Typography level="title-lg">{result.title}</Typography>
                        </Stack>
                        <Typography level="body-sm">{authorsString}</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" spacing={1} sx={{ paddingTop: '1em' }}>
                        <Typography level="title-sm" textAlign={'justify'}>
                            {result.abstract ? result.abstract : 'No abstract available.'}
                        </Typography>
                        <Divider>
                            <Chip label={
                                <Stack direction="row" spacing={1}>
                                    <IoSparklesOutline size={20} />
                                    <Typography level="title-sm">AI Insights</Typography>
                                </Stack>
                            } size="small" />
                        </Divider>
                        <Stack direction="row" spacing={1}>
                            <Typography level="body-sm" sx={{paddingTop: 2}} textAlign={aiInsights ? 'justify' : 'center'}>
                                {aiInsights ? aiInsights : 'No AI insights available yet.'}
                            </Typography>
                            {aiInsights === 'Loading AI insights...' && <CircularProgress size='sm' variant='plain'/>}
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Stack direction="column">
                <Stack direction="row" spacing={1} sx={{marginBottom: '0.5em'}}>
                { result.openAccessPdf && <Chip label=
                {
                    <Stack direction="row" spacing={1}>
                        <FaCrown size={18} color={'#FFD700'} />
                        <Typography level="title-sm">Open Access</Typography>
                    </Stack>
                }
                 variant='outlined' size="small" sx={{width: 130}} /> }
                { result.citationCount > 100 && <Chip label=
                {
                    <Stack direction="row" spacing={1}>
                        <FaFire size={16} color={'#FF4500'} />
                        <Typography level="title-sm">Highly Cited</Typography>
                    </Stack>
                }
                 variant='outlined' size="small" sx={{width: 130}} /> }
                { isOnDatabase && <Chip label=
                {
                    <Stack direction="row" spacing={1}>
                        <HiMiniMagnifyingGlassCircle size={16} color={'#FF4500'} />
                        <Typography level="title-sm">Ready to Focus!</Typography>
                    </Stack>
                }
                 variant='outlined' size="small" sx={{width: 160}} /> }
                </Stack>
                <Stack direction="row" sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <Stack direction="row" spacing={1}>
                        <Typography level="body-sm">{result.year}</Typography>
                        <Typography level="body-sm" fontWeight={'bold'}>{result.citationCount} citations</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" size="sm"
                            startIcon={<IoIosJournal size={20} />}
                            onClick={() => window.open(`https://www.semanticscholar.org/reader/${result.paperId}`, '_blank')}
                        >
                            <Typography level="body-sm">Read in Semantic Scholar</Typography>
                        </Button>
                        <Button variant="outlined" size="sm"
                            disabled={!result.openAccessPdf}
                            startIcon={<IoCloudDownloadSharp size={20} />}
                            onClick={() => window.open(result.openAccessPdf.url, '_blank')}
                            >
                            <Typography level="body-sm">Download</Typography>
                        </Button>
                        <Tooltip title="Click this to open in Focus!">
                            { !isOnDatabase ? <Button variant="outlined" size="sm"
                                disabled={!result.openAccessPdf || isLoadingRequestFocus}
                                startIcon={<HiMiniMagnifyingGlassCircle size={20} />}
                                onClick={requestFocus}
                                >
                                <Typography level="body-sm">Request Focus!</Typography>
                            </Button>
                            :
                            <Button variant="outlined" size="sm"
                                disabled={!result.openAccessPdf || isLoadingRequestFocus}
                                startIcon={<HiMiniMagnifyingGlassCircle size={20} />}
                                onClick={openInFocus}
                                >
                                <Typography level="body-sm">Open in Focus!</Typography>
                            </Button>
                            }
                        </Tooltip>
                    </Stack>
                </Stack>
                <Stack direction="column" sx={{marginTop: "1em"}}>
                    { isLoadingRequestFocus && <LinearProgress /> }
                    { errorMessageRequestFocus && <Typography level="title-sm" color="error">{errorMessageRequestFocus}</Typography> }
                    { successMessageRequestFocus && <Typography level="title-sm" color="success">{successMessageRequestFocus}</Typography> }
                </Stack>
            </Stack>
        </Card>
    );
}