import Input from '@mui/joy/Input';
import React, { useState } from 'react';
import { ReactComponent as Logo } from '../assets/logo-no-slogan-no-background.svg';
import { RiLockPasswordFill } from "react-icons/ri";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [requestSuccess, setRequestSuccess] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match.');
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, {
            token: token,
            password: password,
        })
        .then(response => {
            setRequestSuccess('Your password has been reset successfully.');
            setError('');
            setTimeout(() => {
                navigate('/login');
            }, 3000); // Redirect to login page after 3 seconds
        })
        .catch(error => {
            console.error('Error resetting password:', error);
            setError('An error occurred. Please try again.');
        });
    }

    return (
        <main style={styles.main_container}>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <Logo style={styles.logo} />
            <div className="gap-4 flex flex-col items-center">
                <h1 className="text-2xl text-center">
                    Create New Password
                </h1>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.inputGroup}>
                        <label htmlFor="password"></label>
                        <Input
                            sx={{ backgroundColor: 'white', boxShadow: '0 1px 4px rgba(0,0,0,0.2)' }}
                            startDecorator={<RiLockPasswordFill size={15} />}
                            variant='soft'
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Enter your new password'
                            required
                        />
                    </div>

                    <div style={styles.inputGroup}>
                        <label htmlFor="confirm-password"></label>
                        <Input
                            sx={{ backgroundColor: 'white', boxShadow: '0 1px 4px rgba(0,0,0,0.2)' }}
                            startDecorator={<RiLockPasswordFill size={15} />}
                            variant='soft'
                            type="password"
                            id="confirm-password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder='Confirm your new password'
                            required
                        />
                    </div>

                    {passwordError && <text style={{ color: 'red', fontSize: '0.8rem' }}>{passwordError}</text>}
                    {error && <text style={{ color: 'red', fontSize: '0.8rem' }}>{error}</text>}
                    {requestSuccess && <text style={{ color: 'green', fontSize: '0.8rem' }}>{requestSuccess}</text>}

                    <button 
                        type="submit"
                        className="bg-emerald-500 rounded-md p-1 text-white hover:bg-emerald-300"
                    >
                        Reset Password
                    </button>
                </form>
            </div>
        </main>
    );
}

const styles = {
    main_container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      marginTop: '-3rem',
    },
    logo: {
      width: '15rem',
      height: '15rem',
      margin: '0',
      marginBottom: '-5rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '22rem',
    },
    inputGroup: {
      marginBottom: '15px',
      display: 'flex',
      flexDirection: 'column',
    },
};
