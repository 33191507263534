import './App.css';
import Chat from './pages/Chat.js';
import Signin from './pages/Signin.js';
import Signup from './pages/Signup.js';
import Account from './pages/Account.js';
import Focus from './pages/Focus.js';
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import React from 'react';
import SparkQuery from './pages/SparkQuery.jsx';
import { useTour } from '@reactour/tour';
import axios from 'axios';
import ForgotPassword from './pages/ForgotPassword.jsx';
import ResetPassword from './pages/ResetPassword.jsx';


export const MainUserContext = React.createContext(null);


function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});

  React.useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/api/users/' + userId).then((response) => {
        setUser(response.data[0]);
        console.log('User:', response.data[0]);
      });
  }, [userId]);

  const { setIsOpen } = useTour();

  React.useEffect(() => {
    if (!loggedIn) {
      return;
    }
    axios.get(process.env.REACT_APP_API_URL + '/api/tours/starter/' + userId)
      .then((response) => {
        if (response.data.length === 0) {
          setIsOpen(true);
        }
        axios.post(process.env.REACT_APP_API_URL + '/api/tours/starter/', {user_id: userId})
          .then((response) => {
            console.log('Tour started:', response.data);
          })
          .catch((error) => {
            console.error('Error starting tour:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching tour:', error);
      });
  }, [loggedIn, userId, setIsOpen]);

  const openTutorial = () => {
    setIsOpen(true);
  };

  return (
    <>
    <MainUserContext.Provider value={{ userId, setUserId, email, setEmail, loggedIn, setLoggedIn, user, setUser }}>
      <Routes>
          <Route path="*" element={<Chat openTutorial={openTutorial} />} />
          <Route path="/signin" element={<Signin/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/account" element={<Account/>} />
          <Route path="/focus/:chat_id?" element={<Focus/>} />
          <Route path="/sparkquery" element={<SparkQuery/>} />
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/reset-password" element={<ResetPassword/>} />
      </Routes>
    </MainUserContext.Provider>
    </>
  );
}

export default App;
