import Input from '@mui/joy/Input';
import React, { useState } from 'react';
import { ReactComponent as Logo} from '../assets/logo-no-slogan-no-background.svg';
import { FaUser } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import Checkbox from '@mui/joy/Checkbox';
import { Link } from "react-router-dom"
import Card from '@mui/joy/Card';
import axios from 'axios';
import { useEffect } from 'react';
import { MainUserContext } from '../App';

import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom';

export default function ForgotPassword() {

    const { loggedIn } = React.useContext(MainUserContext);

    const [emailError, setEmailError] = useState('');
    const [requestSuccess, setRequestSuccess] = useState('');
    const [requestEmail, setRequestEmail] = useState('');

    const navigate = useNavigate();
  
    useEffect(() => {
      if (loggedIn) {
          navigate('/chat');
      }
    }, [loggedIn]);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle login logic here
      // You can add authentication logic here
      axios.post(process.env.REACT_APP_API_URL + '/api/request-password-reset', {
        mail: requestEmail,
      }).then(response => {
        setRequestSuccess('Password reset request sent');
      }).catch(error => {
        console.error(error);
        setEmailError('An error occurred');
        return;
      });
    }

    return (
        <main style={styles.main_container}>
        <Helmet>
            <title>Forgot password</title>
        </Helmet>
        <Logo style={styles.logo} />
        <div
            className="gap-4 flex flex-col items-center"
        >
            <h1
                className="text-2xl text-center"
            >
                Reset Password
            </h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <label htmlFor="email"></label>
            <Input
              sx={{ backgroundColor: 'white', boxShadow: '0 1px 4px rgba(0,0,0,0.2)' }}
              startDecorator={<FaUser size={15} />}
              variant='soft'
              type="email"
              id="email"
              value={requestEmail}
              onChange={(e) => setRequestEmail(e.target.value)}
              placeholder='Enter your email'
              required
            />
            <text style={{ color: 'red', fontSize: '0.8rem' }}>{emailError}</text>
            </div>
            <text style={{ color: 'green', fontSize: '0.8rem' }}>{requestSuccess}</text>
            <button type="submit"
                className="bg-emerald-500 rounded-md p-1 text-white hover:bg-emerald-300"
            >
                Reset
            </button>
        </form>
      </div>
    </main>
    );
}

const styles = {
    main_container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      marginTop: '-3rem',
    },
    container: {
      width: '22rem',
      margin: '0',
      padding: '2rem',
      borderRadius: '0.8rem',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      backgroundColor: '#dfffe3bb',
  
    },
    logo: {
      width: '15rem',
      height: '15rem',
      margin: '0',
      marginBottom: '-5rem',
    },
    title: {
      textAlign: 'center',
    },
    show_pass_container: {
      display: 'flex',
      alignItems: 'center',
    },
    pass_checkbox: {
      padding: '0.5rem 0',
      paddingRight: '0.5rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputGroup: {
      marginBottom: '15px',
      display: 'flex',
      flexDirection: 'column',
    },
  };