import { Typography, Stack } from "@mui/joy"
import references_icon from "../assets/references.png"
import reference_to_focus from "../assets/reference_to_focus.png"
import switch_to_chat from "../assets/switch_to_chat.png"


export default function NoPaper() {
    return (
        <Stack
            direction="column"
            spacing={4}
            sx={{
                flexGrow: 1,
                width: '100%',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Typography level="h1">How to use Focus!</Typography>
            <Stack direction="row" spacing={2} sx={{justifyContent: 'center', alignItems: 'center'}}>
                <Typography level="h4">1. Start a new SparkAI chat</Typography>
                <img src={switch_to_chat} alt="Switch to Chat" style={{width: 100}} />
            </Stack>
            <Stack direction="row" spacing={2} sx={{justifyContent: 'center', alignItems: 'center'}}>
                <Typography level="h4">2. Click on the references icon</Typography>
                <img src={references_icon} alt="References icon" style={{width: 100}} />
            </Stack>
            <Typography level="h4">3. Select a paper and click "Focus!"</Typography>
            <img src={reference_to_focus} alt="References to Focus" style={{width: 750}} />
            <Typography level="h4">4. Learn by asking the paper!</Typography>
        </Stack>
    )
}