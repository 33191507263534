import React from 'react';
import { SparkQueryContext } from '../pages/SparkQuery';
import ResultView from './ResultView';
import { Stack } from '@mui/joy';
import { Pagination } from '@mui/material';
import axios from 'axios';


export default function ResultsList() {

    const {page, setPage} = React.useContext(SparkQueryContext);
    const {results, setResults} = React.useContext(SparkQueryContext);
    const {nResults, setNResults} = React.useContext(SparkQueryContext);
    const {searchQuery} = React.useContext(SparkQueryContext);

    const handleChange = (event, value) => {
        setPage(value);
        setResults([]);
        axios.get(process.env.REACT_APP_API_URL + '/api/semantic_scholar/paper/search?query=' + searchQuery + '&offset=' + (value-1)*10)
            .then((response) => {
                setNResults(response.data.total);
                setResults(response.data.data);
            })
            .catch((error) => {
                console.error('Error searching for papers:', error);
            }
        );
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{ justifyContent: 'start', alignItems: 'center', paddingTop: '1em' }}
        >
            {results.map((result, index) => (
                <ResultView key={index} result={result} />
            ))}
            <Pagination count={Math.round(nResults/10)} variant="outlined" color="primary" 
                onChange={handleChange} page={page} />
        </Stack>
    )
}