import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Stack from '@mui/joy/Stack';
import { FaFire } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { FireIcon } from '@heroicons/react/20/solid';

import { UserContext } from '../pages/Chat';
import { MainUserContext } from '../App';
import { Badge } from '@mui/material';
import axios from 'axios';


function SourceComponent({ source, n }) {

    const { userId } = React.useContext(MainUserContext);

    const navigate = useNavigate();

    const createFocusChat = ( {conversation_name, user_id, paper_id, title, authors, abstract, url} ) => {
        axios.post(process.env.REACT_APP_API_URL + '/api/focus/chat/create', {
            conversation_name: conversation_name,
            user_id: user_id,
            paper_id: paper_id,
            title: title,
            authors: authors,
            abstract: abstract,
            url: url
        })
        .then(response => {
            navigate('/focus/' + response.data.conversation_id);
        })
        .catch(error => {
            console.error('Error creating focus chat:', error);
        });
    }

    return (
        <Accordion sx={{ padding: 3 }}>
            <AccordionSummary>
                <div
                    className="flex flex-row w-full justify-between items-center pr-7 gap-4"
                >   
                <div
                    className="flex w-11/12"
                >
                    <text
                        className="text-md font-bold"
                        >
                        {source.title}
                    </text>
                </div>
                    { source.is_relevant ?
                        <div
                            className="flex flex-row items-center gap-1 bg-emerald-100 border border-emerald-500 rounded-full px-2"
                        >
                            <FireIcon
                                className="w-5 h-5 text-emerald-500"
                            />
                            <text 
                                className="text-sm font-semibold text-emerald-700 text-center text-nowrap"
                            >
                                Very relevant
                            </text>
                        </div> : <></>
                    } 
                </div>
            </AccordionSummary>
            <AccordionDetails>
            <div
                className="flex flex-col gap-2"
            >
                <text
                    className="text-sm text-gray-500"
                >
                    {source.authors}
                </text>
                <text
                    className="text-sm text-gray-700 text-justify leading-relaxed"
                >
                    {source.abstract ? source.abstract : "No abstract available, sorry for the inconvenience!"}
                </text>
                <div 
                    className="flex flex-row gap-2"
                >
                    <button
                        className="w-1/2 h-10 bg-emerald-50 hover:bg-white text-emerald-800 font-semibold rounded-md shadow-md border border-emerald-500"
                        onClick={() => window.open(source.url, '_blank')}
                    >
                            Open
                    </button>
                    <button
                        className="w-1/2 h-10 bg-emerald-400 hover:bg-emerald-200 text-white font-semibold rounded-md shadow-inner border border-emerald-800"
                        onClick={() => createFocusChat({
                            conversation_name: source.title,
                            user_id: userId,
                            paper_id: source.paper_id,
                            title: source.title,
                            authors: source.authors,
                            abstract: source.abstract,
                            url: source.url
                        })}
                    >
                        Focus!
                    </button>
                </div>
            </div>
            </AccordionDetails>
        </Accordion>
    );
}

function SourcesList() {
    const { sources, setSources } = React.useContext(UserContext);

    return (
        <AccordionGroup sx={{ maxWidth: 'inherit' }}>
            
            {sources.map((source, index) => {
                return (
                    <SourceComponent key={index} source={source} n={index} />
                );
            })}
        </AccordionGroup>
    );
}

export default function BasicModal() {
    const { openSources, setOpenSources } = React.useContext(UserContext);

    return (
        <React.Fragment
        >
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openSources}
            onClose={() => setOpenSources(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999999 }}
        >
            <Sheet
            variant="outlined"
            sx={{ maxWidth: 1300, borderRadius: 'md', p: 3, boxShadow: 'lg', overflow: 'auto', maxHeight: '90vh' }}
            >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <SourcesList />
            </Sheet>
        </Modal>
        </React.Fragment>
    );
}