import React from 'react';
import { ToggleButtonGroup, Button } from '@mui/joy';
import { SparkQueryContext } from '../pages/SparkQuery';
import axios from 'axios';


export default function QueryTabs() {
    const {refinedQueries, setRefinedQueries} = React.useContext(SparkQueryContext);
    const {value, setValue} = React.useContext(SparkQueryContext);
    const {results, setResults} = React.useContext(SparkQueryContext);
    const {nResults, setNResults} = React.useContext(SparkQueryContext);
    const {showResults, setShowResults} = React.useContext(SparkQueryContext);
    const {setPage} = React.useContext(SparkQueryContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setPage(1);
        setResults([]);
        axios.get(process.env.REACT_APP_API_URL + '/api/semantic_scholar/paper/search?query=' + newValue + '&offset=0')
            .then((response) => {
                setNResults(response.data.total);
                if (response.data.total === 0) {
                    setResults(-1);
                }
                else {
                    setResults(response.data.data);
                }
                setShowResults(true);
            })
            .catch((error) => {
                console.error('Error searching for papers:', error);
            }
        );
    }

    return (
        <ToggleButtonGroup
            size="md"
            value={value}
            onChange={handleChange}
        >
            {refinedQueries.map((query, index) => (
                <Button
                    key={index}
                    value={query}
                    style={{
                        backgroundColor: value === query ? "#007bff" : "transparent",
                        color: value === query ? "white" : "#000",
                        border: value === query ? "1px solid #007bff" : "1px solid #ccc"
                    }}
                >
                    {query}
                </Button>
            ))}
        </ToggleButtonGroup>
    );
}