import PersistentDrawer from "../components/PersistentDrawer"
import * as React from 'react';
import TopHeader from "../components/TopHeader";
import ChatBot from "../components/ChatBot";
import ChatInput from "../components/ChatInput";
import { useEffect } from "react";
import ChatSpeedDial from "../components/ChatSpeedDial";
import axios from "axios";
import SourcesModal from "../components/SourcesModal";
import { Typography } from "@mui/joy";
import { Button } from "@mui/joy";
import { Tooltip } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { MainUserContext } from "../App";

import { Helmet } from 'react-helmet';
import SurveyFab from "../common_components/SurveyFab";
import RightSideMenu from "../common_components/RightSideMenu";
import CustomIconButton from "../common_components/CustomIconButton";

import { HeartIcon } from "@heroicons/react/20/solid";
import { BugAntIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

import { FaBug } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";


export const UserContext = React.createContext(null);


export default function Chat({openTutorial}) {

    const { loggedIn } = React.useContext(MainUserContext);
    const { userId } = React.useContext(MainUserContext);

    const { setUser } = React.useContext(MainUserContext);
    const { setUserId } = React.useContext(MainUserContext);
    const { setLoggedIn } = React.useContext(MainUserContext);
    const { setEmail } = React.useContext(MainUserContext);

    const [isOpen, setIsOpen] = React.useState(true);
    const [enableChatInput, setEnableChatInput] = React.useState(false);
    const [currentChatId, setCurrentChatId] = React.useState(null);

    const [messageIsLoading, setMessageIsLoading] = React.useState(false);
    const [dots, setDots] = React.useState('');
    const [messageIsLoadingDetails, setMessageIsLoadingDetails] = React.useState("1/4 - Understanding your question")

    const [messages, setMessages] = React.useState([]);
    const [chats, setChats] = React.useState([]);

    const [openSources, setOpenSources] = React.useState(false);
    const [sources, setSources] = React.useState([]);
    const [ newSourcesNotification, setNewSourcesNotification ] = React.useState(false);

    const [ showSurvey, setShowSurvey ] = React.useState(false);

    const drawerSize = 300;
    const drawerMargin = 15;
    const extraMargin = 15;
    const marginLeft = isOpen ? drawerSize + drawerMargin + extraMargin : 0;

    const [ model, setModel ] = React.useState('fast');

    React.useEffect(() => {
        console.log('Checking if user is logged in');
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/protected', {withCredentials: true}).then(response => {
            console.log('User logged response:', response.data);
            if (response.data.user) {
                axios.get(process.env.REACT_APP_API_URL + '/api/users/mail/' + response.data.user.mail).then((response) => {
                    setUser(response.data[0]);
                    setUserId(response.data[0].user_id);
                    setLoggedIn(true);
                    setEmail(response.data[0].mail);
                });
            } else {
                setLoggedIn(false);
                window.open('https://ederspark.com/?page=platform', '_self');
            };
        }
        ).catch(error => {
            console.error(error);
            setLoggedIn(false);
            window.open('https://ederspark.com/?page=platform', '_self');
        });
      }, []);

    // Check if window is smaller than 768px
    const [buttonsDirection, setButtonsDirection] = React.useState('row');

    React.useEffect(() => {
        console.log('window.innerWidth:', window.innerWidth);
        if (window.innerWidth < 768) {
            setButtonsDirection('col');
        }
        else {
            setButtonsDirection('row');
        }
    }, [window.innerWidth]);


    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/polls/' + userId)
        .then(response => {
            setShowSurvey(!response.data.user_has_answered);
        })
        .catch(error => {
            console.error('Error fetching survey data:', error);
        }
    );
    }, [userId]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/sources/' + currentChatId)
        .then(response => {
            setSources(response.data);
        })
        .catch(error => {
        });
    }, [openSources, currentChatId, messageIsLoading]);

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }
    const [currentChatName, setCurrentChatName] = React.useState('New chat');
    return (
        <UserContext.Provider value={{ messages, setMessages, chats, setChats, messageIsLoading, setMessageIsLoading, dots, setDots, messageIsLoadingDetails, setMessageIsLoadingDetails, currentChatId, setCurrentChatId, currentChatName, setCurrentChatName, sources, setSources, openSources, setOpenSources, newSourcesNotification, setNewSourcesNotification, model, setModel }}>
            <Helmet>
                <title>SparkAI Chat</title>
            </Helmet>
            <div
                className="h-screen"
            >
                <PersistentDrawer isOpen={isOpen} drawerSize={drawerSize} drawerMargin={drawerMargin} setCurrentChatName={setCurrentChatName} />
                <main 
                    className='h-full'
                style={{marginLeft: marginLeft, transition: 'margin-left 0.3s' }}
                >
                    <TopHeader isOpen={isOpen} toggleDrawer={toggleDrawer} currentChatName={currentChatName}/>
                    <div
                        className='step1 flex flex-col justify-center items-center h-full' 
                    >
                        <ChatBot setEnableChatInput={setEnableChatInput}/>
                        <ChatInput enableChatInput={enableChatInput}/>
                        <text 
                            className="text-gray-500 text-xs text-center fixed bottom-0"
                        >
                            This tool can make mistakes, always check references. Made by&nbsp;
                            <a 
                                className="text-blue-500 hover:cursor-pointer hover:underline"
                            href="https://ederspark.com" target="_blank" rel="noreferrer">
                                EderSpark
                            </a>
                        </text>
                    </div>
                </main>
            </div>
            <SourcesModal />
            <div
            style={{position: 'fixed', top: 100, right: 50, display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center', alignItems: 'center'}}
        >
        <RightSideMenu />
        </div>
        <div
            className={`flex flex-${buttonsDirection} fixed top-[5em] right-4 gap-3`}
        >
            <Tooltip title="Give feedback!" position="left">
            <button
                className="feedback-button"
                onClick={() => window.open('https://forms.gle/fs8mWTtWNxmyjR6Y7', '_blank')}
            >
                <HeartIcon
                    className="w-6 h-6 text-green-400 cursor-pointer"
                />
            </button>
            </Tooltip>
            <Tooltip title="Report a bug" position="left">
            <button
                className="report-bug-button"
                onClick={() => window.open('https://forms.gle/MoNRxo8xw9qtg38n6', '_blank')}
            >
                <BugAntIcon
                    className="w-6 h-6 text-red-400 cursor-pointer"
                />
            </button>
            </Tooltip>
            <Tooltip title="See tutorial" position="left">
            <button
                className="repeat-tour-button"
                onClick={() => openTutorial()}
            >
                <InformationCircleIcon
                    className="w-6 h-6 text-blue-400 cursor-pointer"
                />
            </button>
            </Tooltip>
        </div>
        {showSurvey && <SurveyFab setShowSurvey={setShowSurvey} />}
        </UserContext.Provider>
    )
}