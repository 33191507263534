import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './input.css';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { TourProvider } from '@reactour/tour'

// Set axios base headers
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = "c46801db-29ee-408c-bf24-6b16ba8131ac";

if (process.env.NODE_ENV !== "development")
  console.log = () => {};

const steps = [
  {
    selector: '.right-side-menu',
    content: 'Welcome to EderSpark! I will guide you through our AI platform. If you are ready to elevate your research process, go to the next step to get started! ;)',
  },
  {
    selector: '.right-side-menu',
    content: 'First of all, here you can find our other AI tools, such as Focus and SparkQuery. I recommend you to try them out! :)',
  },
  {
    selector: '.first-step',
    content: 'SparkAI is a powerful AI-powered chatbot that will help you with your research providing fully cited and referenced answers to any question you may have.',
  },
  {
    selector: '.no-messages-input',
    content: 'Here you can introduce your question and press "Enter" or click on the send button to ask.',
  },
  {
    selector: '.persistent-drawer',
    content: 'Here you will have all your chats and you can click on any of them to continue the conversation.',
  },
  {
    selector: '.feedback-button',
    content: 'If you have any feedback, you can click on this button and let us know. We are always looking to improve our platform!',
  },
  {
    selector: '.report-bug-button',
    content: 'If you find any bug, you can click on this button and let us know. We will fix it as soon as possible!',
  },
  {
    selector: '.repeat-tour-button',
    content: 'If you want to repeat this tutorial, you can click on this button and start over.',
  },
  {
    selector: '.first-step',
    content: 'That\'s it! You are now ready to start using EderSpark!',
  }
]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TourProvider steps={steps}>
        <App />
      </TourProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
