import { LuAward } from "react-icons/lu";
import React from 'react';
import { CircularProgress } from "@mui/joy";
import SourcesFab from './SourcesFab';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import axios from 'axios';
import { UserContext } from '../pages/Chat';

import { calcMaxInputLength } from '../utils/utils';


export default function ChatInput( {enableChatInput} ) {
    const [chatInput, setChatInput] = React.useState('');
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { messageIsLoadingDetails, setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const { openSources, setOpenSources } = React.useContext(UserContext);

    const { sources, setSources } = React.useContext(UserContext);

    const { model } = React.useContext(UserContext);
    const [ aiEndpoint, setAiEndpoint ] = React.useState("/api/ia/call");

    React.useEffect(() => {
        if (model === 'smart') {
            console.log('Setting smart model');
            setAiEndpoint("/api/ia/ao-call");
        } else {
            console.log('Setting fast model');
            setAiEndpoint("/api/ia/call");
        }
    }, [model]);

    const areSourcesEmpty = () => {
        return !sources.length
    }

    React.useEffect(() => {
        let interval;
      
        interval = setInterval(() => {
        console.log('Looking for task with id:', currentChatId);
    
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/ia/tasks/${currentChatId}`)
            .then((response) => {
                setMessageIsLoadingDetails(`${response.data.progress} - ${response.data.status}`);
                console.log('Task status:', response.data.status);
                console.log('Task progress:', response.data.progress);
        
                if (response.data.status === 'Done') {
                    setMessageIsLoading(false);
                    setMessageIsLoadingDetails('1/4 - Understanding your question');
                    clearInterval(interval); // Stop the interval once the task is complete
                }
                else {
                    setMessageIsLoading(true);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    // Task not found, stop polling
                    console.log('Task not found, stopping polling.');
                    setMessageIsLoading(false);
                    setMessageIsLoadingDetails('1/4 - Understanding your question');
                } else {
                    console.error('Error fetching task:', error);
                }
            });
        }, 3000); // Run every 2.5 seconds
      
        return () => clearInterval(interval); // Clear the interval when the component unmounts
      }, [messages, currentChatId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (calcMaxInputLength(model, chatInput.length)) {
            alert('Your message is too long. Please try again with a shorter message. Length: ' + chatInput.length);
            return;
        }
        messages.push({role_id: 1, content: chatInput});
        setMessageIsLoading(true);
        axios.post(process.env.REACT_APP_API_URL + '/api/messages', {
            conversation_id: currentChatId,
            role_id: 1,
            message: chatInput
        }).then(response => {
            setChatInput('');
            axios.post(process.env.REACT_APP_API_URL + aiEndpoint, {
                query: chatInput,
                chat_history: messages,
                conversation_id: currentChatId
            }).then(response => {
                console.log('Creating message');
            }).catch(error => {
                console.error('Error refining query');
                alert('Error processing your message. Please try again with another wording.');
            });
        }).catch(error => {
            console.error('Error creating message:', error);
            setMessageIsLoading(false);
            setMessageIsLoadingDetails('1/4 - Understanding your question');
            alert('Error processing your message. Please try again with another wording.');
        });
    }

    return (
        <>
        { enableChatInput &&
            <div className="flex fixed bg-white bottom-0 w-full pb-8 shadow-[0_0_100px_rgba(255,255,255,0.9)]"

            >
            <form
                className="w-full flex justify-center"
                onSubmit={handleSubmit}
            >
                <input required
                    className="input-chat w-4/6 md:w-5/12"
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    startDecorator={<LuAward/>}
                    variant="outline" placeholder="Type a message"
                    disabled={messageIsLoading}
                />
                <div
                    className="flex justify-center items-center relative right-8"
                >
                    <button
                        className="bg-emerald-500 hover:bg-emerald-300 w-6 h-6 rounded-lg shadow-inner flex justify-center items-center"
                        type='submit'
                        disabled={messageIsLoading}>
                            {messageIsLoading ? 
                            <CircularProgress 
                            variant='plain'
                            thickness={1}
                            size="sm"
                            /> : 
                            <ArrowRightIcon
                                className="text-white w-5 h-5"
                            />
                        }
                    </button>
                </div>
            </form>
            {!areSourcesEmpty() && <SourcesFab setOpenSources={setOpenSources} />}
        </div>
        }
        </>
    )
}