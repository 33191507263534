import { Box, Card, Input, Skeleton, Stack, Typography } from '@mui/joy';
import '../index.css';
import * as React from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'
import sparkaiLogo from '../assets/sparkai_new_only_logo.png';
import { MdOutlineBrowserUpdated } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import { BiConversation } from "react-icons/bi";
import { CircularProgress } from "@mui/joy";
import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { DocumentCheckIcon } from '@heroicons/react/20/solid';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid';

import { UserContext } from '../pages/Chat';

import '../index.css';
import { MainUserContext } from '../App';
import { Button } from '@mui/joy';

import { calcMaxInputLength } from '../utils/utils';


function ChatMessage({ role_id, content, lastSecondsElapsed }) {
    const displayRole = role_id === 1 ? "You" : "SparkAI";
    const roleClass = role_id === 1 ? "message-user" : "message-bot";
    const containerClass = role_id === 1 ? "message-container-user" : "message-container-bot";
    const showTime = role_id === 2 && lastSecondsElapsed;
    return (
        <div className={containerClass}>
            {showTime ?
            <Stack direction='row' spacing={2} sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            <Typography variant="body1" style={{fontWeight: 'bold'}}>{displayRole}</Typography>
            <Typography level='body-sm' sx={{whiteSpace: 'nowrap'}}> {lastSecondsElapsed.toFixed(1)} secs</Typography>
            </Stack> :
            <Typography variant="body1" style={{fontWeight: 'bold'}}>{displayRole}</Typography>
            }
            <div
                className={`${roleClass} break-all overflow-hidden w-full`}
            >
                <ReactMarkdown 
                    className="text-xs md:text-sm text-gray-800 text-justify md:leading-loose"
                >
                    {content}
                </ReactMarkdown>
            </div>
        </div>
    );
}

function MessagesList( {messages} ) {
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {dots, setDots} = React.useContext(UserContext);
    const {messageIsLoadingDetails, setMessageIsLoadingDetails} = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    React.useEffect(() => {
        if (!messageIsLoading) return; // Si el mensaje ya ha sido cargado, no es necesario mostrar los puntos
        const interval = setInterval(() => {
            setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : '')); // Añadir puntos o resetear
        }, 500); // Intervalo de medio segundo

        return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
    }, [messageIsLoading]);

    const [secondsElapsed, setSecondsElapsed] = React.useState(0);
    const [lastSecondsElapsed, setLastSecondsElapsed] = React.useState(null);

    React.useEffect(() => {
        let interval;
        
        if (messageIsLoading) {
        // Start the interval to update time every 100ms
        interval = setInterval(() => {
            setSecondsElapsed(prev => prev + 0.1); // Increment by 0.1 every 100ms
        }, 100);
        } else {
            // If not processing, clear the timer
            setLastSecondsElapsed(secondsElapsed); // Save last time when processing stops
            setSecondsElapsed(0); // Reset timer when processing stops
        }

        // Clear interval when component unmounts or stops processing
        return () => clearInterval(interval);
    },  [messageIsLoading, currentChatId]);


    return (
        <div className="flex flex-col flex-grow rounded-lg gap-2 overflow-y-auto w-full justify-start items-center mb-24 pt-28">
            <div className="h-full w-4/6">
            {messages.map((chat, index) => (
                <ChatMessage key={index} role_id={chat.role_id} content={chat.content} lastSecondsElapsed={lastSecondsElapsed}/>
            ))}
            { messageIsLoading &&
            <>
            <Stack direction='row' spacing={2} sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            <Stack direction='row' spacing={2} sx={{alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
                <CircularProgress 
                    variant='plain'
                    thickness={1}
                    size="sm"
                    />
                <Typography variant="body1" style={{textAlign: 'left', marginTop: '1rem'}}>{messageIsLoadingDetails}{dots}</Typography>
            </Stack>
                <Typography level='body-sm' sx={{whiteSpace: 'nowrap'}}>
                    {secondsElapsed.toFixed(1)} secs
                </Typography>
            </Stack>
                <Skeleton
                    variant="rectangular"
                    width={825} // Puedes ajustar el tamaño al que desees
                    height={300}
                    sx={{ borderRadius: '8px', zIndex: -1, marginTop: '1rem' }}
                    />
            </>
            }
            </div>
        </div>
    )
}

function NoMessages( {createChat} ) {
    const [firstMessage, setFirstMessage] = React.useState('');
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    // Cards hovers
    const [firstCardHover, setFirstCardHover] = React.useState(false);
    const [secondCardHover, setSecondCardHover] = React.useState(false);
    const [thirdCardHover, setThirdCardHover] = React.useState(false);

    return (
        <div 
            className="first-step flex flex-col justify-center items-center rounded-lg shadow-lg gap-5  bg-green-100 p-4 h-6/12 w-5/12 min-h-[400px] min-w-[300px]"
        >
                <div 
                    className="flex flex-col justify-center items-center gap-3"
                >
                    <img 
                        className='w-8 h-8'
                        src={sparkaiLogo} alt="SparkAI_logo"
                    />
                    <text
                        className="text-3xl font-semibold text-center text-gray-700"
                    >
                        How can I help you today?
                    </text>
                    <text 
                        className="text-md text-center text-gray-500"
                    >
                        SparkAI is the best AI assistant for your research process. Ask me anything from any topic and I will help you find the information you need.
                    </text>
                </div>
                <div
                    className="w-full flex flex-row gap-3"
                >
                    <div
                        className="hello-feature-card"
                        onMouseEnter={() => setFirstCardHover(true)}
                        onMouseLeave={() => setFirstCardHover(false)}
                    >
                        {firstCardHover ?
                        <text
                            className="hello-feature-card-text"
                        >
                            Our database is being updated every day
                        </text>
                        :
                        <>
                        <ArrowPathIcon 
                            className="hello-feature-card-icon"
                            />
                        <text
                            className="hello-feature-card-text"
                            >
                            Up-to-date scientific literature
                        </text>
                        </>
                        }
                    </div>
                    <div 
                        className="hello-feature-card"
                        onMouseEnter={() => setSecondCardHover(true)}
                        onMouseLeave={() => setSecondCardHover(false)}
                    >
                        {secondCardHover ?
                        <text
                            className="hello-feature-card-text"
                        >
                            SparkAI will cite and reference all information found
                        </text>
                        :
                        <>
                        <DocumentCheckIcon
                            className="hello-feature-card-icon"
                        />
                        <text
                            className="hello-feature-card-text"
                        >
                            Fully referenced citations
                        </text>
                        </>
                        }
                    </div>
                    <div
                        className="hello-feature-card"
                        onMouseEnter={() => setThirdCardHover(true)}
                        onMouseLeave={() => setThirdCardHover(false)}
                    >
                        {thirdCardHover ?
                        <text
                            className="hello-feature-card-text"
                        >
                            Enjoy a natural and interactive conversation 
                        </text>
                        :
                        <>
                        <ChatBubbleLeftRightIcon
                        className="hello-feature-card-icon"
                        />
                        <text
                        className="hello-feature-card-text"
                        >
                            Organic conversations
                        </text>
                        </>
                        }
                    </div>
                </div>
                <div 
                    className="w-full"
                >
                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        await createChat({firstMessage: firstMessage, currentChatId: currentChatId});
                    }}>
                        <div
                            className="flex justify-center items-center w-full"
                        >
                            <input required
                                className="input-chat w-full no-messages-input"
                                value={firstMessage}
                                onChange={(e) => setFirstMessage(e.target.value)}
                                placeholder="Type a message" 
                                disabled={messageIsLoading}
                            />
                        </div>
                    </form>
                </div>
        </div>
    );
}

export default function ChatBot( {setEnableChatInput} ) {
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { currentChatName, setCurrentChatName } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);

    const { model } = React.useContext(UserContext);
    const [ aiEndpoint, setAiEndpoint ] = React.useState("/api/ia/call");

    React.useEffect(() => {
        if (model === 'smart') {
            console.log('Setting smart model');
            setAiEndpoint("/api/ia/ao-call");
        } else {
            console.log('Setting fast model');
            setAiEndpoint("/api/ia/call");
        }
    }, [model]);

    React.useEffect(() => {
        let interval;
      
        interval = setInterval(() => {
        console.log('Looking for task with id:', currentChatId);
    
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/ia/tasks/${currentChatId}`)
            .then((response) => {
                setMessageIsLoadingDetails(`${response.data.progress} - ${response.data.status}`);
                console.log('Task status:', response.data.status);
                console.log('Task progress:', response.data.progress);
        
                if (response.data.status === 'Done') {
                    setMessageIsLoading(false);
                    setMessageIsLoadingDetails('1/4 - Understanding your question');
                    clearInterval(interval); // Stop the interval once the task is complete
                }
                else {
                    setMessageIsLoading(true);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    // Task not found, stop polling
                    console.log('Task not found, stopping polling.');
                    setMessageIsLoading(false);
                    setMessageIsLoadingDetails('1/4 - Understanding your question');
                } else {
                    console.error('Error fetching task:', error);
                }
            });
        }, 3000); // Run every 5 seconds
      
        return () => clearInterval(interval); // Clear the interval when the component unmounts
      }, [messages, currentChatId]);      

    const createChat = async ( {firstMessage} ) => {
        // Check if the message is too long
        if (calcMaxInputLength(model, firstMessage.length)) {
            alert('Your message is too long. Please try again with a shorter message. Length: ' + firstMessage.length);
            return;
        }
        setMessageIsLoading(true);
        await axios.post(process.env.REACT_APP_API_URL + '/api/ia/get_chat_name', {
            first_message: firstMessage
        }).then(async response => {
            setCurrentChatName(response.data.chat_name);
            // Make it awaitable
            await axios.post(process.env.REACT_APP_API_URL + '/api/chats', {
                conversation_name: response.data.chat_name,
                user_id: userId,
                first_message: firstMessage
            }).then(async response => {
                const chatId = response.data.chat_id;
                setCurrentChatId(chatId);
                console.log('Creating chat');
                await axios.post(process.env.REACT_APP_API_URL + aiEndpoint, {
                    query: firstMessage,
                    chat_history: messages,
                    conversation_id: chatId
                }).then(response => {
                    console.log('Creating message');
                }).catch(error => {
                    console.error('Error refining query');
                    alert('Error processing your message. Please try again with another wording.');
            });
            }).catch(error => {
                console.error('Error creating chat');
                alert('Error processing your message. Please try again with another wording.');
            });
        }).catch(error => {
            console.error('Error getting chat name');
            setMessageIsLoading(false);
            setMessageIsLoadingDetails('1/4 - Understanding your question');
            alert('Error processing your message. Please try again with another wording.');
        });
    }

    const messagesLength = messages.length;
    React.useEffect(() => {

        if (messagesLength > 0) {
            setEnableChatInput(true);
        } else {
            setEnableChatInput(false);
        }
    }, [messages]);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then((response) => {
                setMessages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId, messageIsLoading]);
    return (
        <>
        {messagesLength > 0 ? <MessagesList messages={messages} /> : <NoMessages createChat={createChat}/>}
        </>
    );
}