import { Box, Button, Input } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { MdChat, MdClose } from "react-icons/md";
import { AiOutlineDrag } from "react-icons/ai";
import { IoResize } from "react-icons/io5";
import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { LuArrowRight } from 'react-icons/lu';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { UserContext } from '../pages/Focus';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from '@heroicons/react/20/solid';


export default function FloatingChatBot() {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const { paper } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    const [messageIsLoading, setMessageIsLoading] = useState(false);

    const [dimensions, setDimensions] = useState({ width: 500, height: 550 });
    const [position, setPosition] = useState({ bottom: 20, right: 100 });

    const [isResizing, setIsResizing] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const messagesEndRef = useRef(null);
    const chatRef = useRef(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then(response => {
                setMessages(response.data);
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId]);

    const handleSendMessage = () => {
        if (newMessage.trim() !== "") {
            setMessageIsLoading(true);
            messages.push({ role_id: 1, content: newMessage });
            axios.post(process.env.REACT_APP_API_URL + '/api/qdrant/search/', {
                paper_id: paper.paper_id,
                query: newMessage
            }).then(response => {
                setNewMessage("");
                axios.post(process.env.REACT_APP_API_URL + '/api/ia/focus/call/', {
                    paper_title: paper.title,
                    paper_abstract: paper.abstract,
                    paper_authors: paper.authors,
                    chat_history: messages,
                    context: response.data.extracts,
                    user_query: newMessage
                }).then(response => {
                    messages.push({ role_id: 2, content: response.data.response });
                    axios.post(process.env.REACT_APP_API_URL + '/api/messages/', {
                        conversation_id: currentChatId,
                        role_id: 1,
                        message: newMessage
                    });
                    axios.post(process.env.REACT_APP_API_URL + '/api/messages/', {
                        conversation_id: currentChatId,
                        role_id: 2,
                        message: response.data.response
                    });
                    setMessageIsLoading(false);
                });
            });
        }
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    // Handle resizing
    const handleMouseDownResize = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsResizing(true);
    };

    const handleMouseMoveResize = (e) => {
        if (isResizing) {
            setDimensions(prevDimensions => ({
                width: Math.max(300, prevDimensions.width - e.movementX), // Decrease width when dragging left, increase when dragging right
                height: Math.max(300, prevDimensions.height - e.movementY), // Decrease height when dragging up, increase when dragging down
            }));
        }
    };
    

    const handleMouseUpResize = () => {
        setIsResizing(false);
    };

    // Handle dragging
    const handleMouseDownDrag = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsDragging(true);
    };

    const handleMouseMoveDrag = (e) => {
        if (isDragging) {
            setPosition(prevPos => ({
                bottom: Math.max(0, prevPos.bottom - e.movementY), // Adjust bottom based on drag movement
                right: Math.max(0, prevPos.right - e.movementX), // Adjust right based on drag movement
            }));
        }
    };

    const handleMouseUpDrag = () => {
        setIsDragging(false);
    };

    // Apply event listeners for resizing and dragging
    useEffect(() => {
        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMoveResize);
            document.addEventListener('mouseup', handleMouseUpResize);
        } else if (isDragging) {
            document.addEventListener('mousemove', handleMouseMoveDrag);
            document.addEventListener('mouseup', handleMouseUpDrag);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMoveResize);
            document.removeEventListener('mouseup', handleMouseUpResize);
            document.removeEventListener('mousemove', handleMouseMoveDrag);
            document.removeEventListener('mouseup', handleMouseUpDrag);
        };
    }, [isResizing, isDragging]);

    return (
        <>
            <div
                className="hidden md:flex justify-center items-center w-20 h-20 fixed bottom-8 right-8 z-[1000]"
            >
                <button
                    className=" bg-purple-500 rounded-full h-16 w-16 flex items-center justify-center shadow-md hover:h-[4.5rem] hover:w-[4.5rem] hover:bg-purple-300"
                    onClick={toggleChat}
                    >
                    <div
                        className="flex flex-col justify-normal items-center"
                        >
                        <ChatBubbleBottomCenterTextIcon
                            className="text-white h-7 w-7"
                            />
                        <text
                            className="text-white font-semibold text-xs"
                            >
                            Open
                        </text>
                    </div>
                </button>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        ref={chatRef}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 50 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            position: 'fixed',
                            bottom: position.bottom,
                            right: position.right,
                            zIndex: 1000,
                            width: dimensions.width,
                            height: dimensions.height,
                        }}
                    >
                        <div
                            className="relative flex flex-col h-5/6 border-2 border-purple-700 bg-white rounded-2xl shadow-xl"
                            sx={{
                                borderRadius: 10,
                                height: '100%',
                                width: '100%',
                                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                                bgcolor: 'white',
                                borderTopRightRadius: 15,
                                borderTopLeftRadius: 30,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 20,
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                            }}
                        >
                            {/* Control Icons */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 2000,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    padding: '0.5rem',
                                }}
                            >
                                {/* Resize Icon */}
                                <div
                                    onMouseDown={handleMouseDownResize}
                                    className="flex items-center justify-center bg-purple-400 rounded-md cursor-move w-6 h-6"
                                >
                                    <IoResize color="white" />
                                </div>

                                {/* Drag Icon */}
                                <div
                                    onMouseDown={handleMouseDownDrag}
                                    className="flex items-center justify-center bg-purple-400 rounded-md cursor-move w-6 h-6"
                                >
                                    <AiOutlineDrag color="white" />
                                </div>
                            </Box>

                            {/* Minimize/Close Icon */}
                            <div
                                onClick={toggleChat}
                                className="absolute top-0 right-0 m-2 flex items-center justify-center bg-purple-400 rounded-md cursor-pointer w-6 h-6"
                            >
                                <MdClose color="white" />
                            </div>

                            {/* Chat Header */}
                            <div
                                className="flex flex-row justify-center items-center bg-purple-600 h-12 rounded-t-xl"
                            >
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Focus
                                </Typography>
                            </div>
                            {/* Chat Messages */}
                            <div
                                className="flex-1 overflow-y-auto p-4 flex flex-col gap-2"
                            >
                                {messages.map((message, index) => (
                                    <div
                                        key={index}
                                        className={`text-black p-3 rounded-lg w-10/12 break-all
                                            ${message.role_id === 1 ? "self-end" : "self-start"} ${message.role_id === 1 ? "bg-purple-100" : "bg-gray-200"}`}
                                    >
                                        <ReactMarkdown className="message-content text-justify leading-relaxed">{message.content}</ReactMarkdown>
                                    </div>
                                ))}
                                <div ref={messagesEndRef} />
                            </div>
                            {/* Chat Input */}
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSendMessage();
                            }}>
                                <Input
                                    placeholder='Type your message here'
                                    required
                                    variant='soft'
                                    disabled={messageIsLoading}
                                    endDecorator={
                                        <button variant='solid'
                                            disabled={messageIsLoading}
                                            className="bg-purple-500 h-8 w-8 rounded-lg flex justify-center items-center hover:bg-purple-300 shadow-md"
                                            size='md'
                                            type='submit'
                                        >
                                            <ArrowRightIcon
                                                className="text-white h-6 w-6"
                                            />
                                        </button>
                                    }
                                    sx={{
                                        width: '100%',
                                        borderRadius: 0,
                                        borderBottomLeftRadius: 20,
                                        borderBottomRightRadius: 20,
                                        minHeight: 50,
                                    }}
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                />
                            </form>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
}