import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { MdOutlineArticle } from "react-icons/md";
import { orange, red } from '@mui/material/colors';
import { motion } from "framer-motion";
import { UserContext } from '../pages/Chat';
import { Stack, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import axios from 'axios';

function DocumentMagnifyingGlassIcon() {
    return (
        <svg
            className="w-6 h-6 md:w-8 md:h-8 text-white"
        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
        </svg>
    ) 
}

export default function SourcesFab() {
    const { openSources, setOpenSources, currentChatId, messages } = React.useContext(UserContext);
    
    // Local state for newSourcesNotification
    const [newSourcesNotification, setNewSourcesNotification] = useState(false);

    useEffect(() => {
        // Check if the user has new sources to read
        axios.get(`${process.env.REACT_APP_API_URL}/api/chat/are_there_new_sources/${currentChatId}`)
            .then(response => {
                if (response.data) {
                    setNewSourcesNotification(true); // Update local state
                }
            })
            .catch(error => {
                console.error('Error checking for new sources:', error);
            });
    }, [messages, currentChatId]);

    // Handle FAB click
    const handleFabClick = () => {
        setOpenSources(true);
        setNewSourcesNotification(false); // Reset notification when FAB is opened
        axios.post(`${process.env.REACT_APP_API_URL}/api/chat/read_sources/${currentChatId}`)
        .then(response => {
        })
        .catch(error => {
            console.error('Error marking sources as read:', error);
        });

    };

    return (
        <div
            // Copy the same props as sx below
            className="fixed bottom-16 right-8 md:right-16 z-[9999999]"
        >
            <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 20,
                    duration: 0.8,
                }}
                // Apply the pulse effect only when `newSourcesNotification` is true
                animate={
                    newSourcesNotification
                        ? {
                            opacity: [1, 0.8, 1],
                            scale: [1, 1.05, 1],
                        }
                        : { opacity: 1, scale: 1 } // No pulse when false
                }
                transition={
                    newSourcesNotification
                        ? {
                            duration: 1.5,
                            repeat: Infinity,
                            repeatType: "loop",
                        }
                        : {}
                }
            >
                <Box sx={{ position: 'relative' }}>
                    <Stack direction="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div
                            className="w-9 h-9 md:w-14 md:h-14 cursor-pointer flex justify-center items-center bg-emerald-500 rounded-full border-2 border-white shadow-md hover:bg-emerald-300"
                            disabled={openSources}
                            onClick={handleFabClick} // Use handleFabClick function here
                        >
                            <DocumentMagnifyingGlassIcon />
                        </div>

                        {/* Conditionally render the notification dot based on `newSourcesNotification` */}
                        {newSourcesNotification && (
                            <div
                                className="absolute top-1 right-3 md:right-2 w-4 h-4 bg-red-500 rounded-full border-2 border-white z-[9999]"
                            />
                        )}
                        
                        <text
                            className="text-sm md:text-md font-semibold text-center text-gray-800"
                        >
                            References
                        </text>
                    </Stack>
                </Box>
            </motion.div>
        </div>
    );
}
